import React, { createContext, useEffect, useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "../Loading";
import { setToken } from "../../services/index";
import { globalConfig } from "../../config";

export const AuthContext = createContext({ userMetadata: null });
export const useAuth = () => useContext(AuthContext);

export const Auth = (props) => {
  const [userMetadata, setUserMetaData] = useState(null);
  const [token, setToken] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();

  const getUserMetadata = async () => {
    const baseURL = `https://${globalConfig.auth0.domain}/api/v2/`;
    const userDetailsByIdUrl = `${baseURL}users/${user.sub}`;

    const accessToken = await getAccessTokenSilently({
      audience: baseURL,
      scope: "read:current_user",
    });
    const metadataResponse = await fetch(userDetailsByIdUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const { user_metadata: userMeta } = await metadataResponse.json();
    setToken(`${userMeta.apiKey}:${userMeta.userId}`);
    setUserMetaData(userMeta);
  };

  useEffect(() => {
    getUserMetadata();
  }, []);
  let value = {
    token,
    userMetadata,
  };
  return (
    <AuthContext.Provider value={value}>
      {!userMetadata ? <Loading /> : props.children}
    </AuthContext.Provider>
  );
};
