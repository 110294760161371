import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

import { Auth } from "./components/Auth/Auth";
import { Loading } from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";

//import logo from './logo.svg';

import RouterConfig from "./components/NavigationComponent/RouterConfig";
import HomePage from "./components/HomePage";
import ContentObjectProvider from './components/contexts/ContentObjectContext'

// import 'bootstrap/dist/css/bootstrap.min.css'
// import MainDashBoard from './components/MainDashboard/MainDashboard'

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 5000,
      },
    },
  });

  console.log(isAuthenticated, isLoading);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : !isAuthenticated ? (
        loginWithRedirect()
      ) : (
        <QueryClientProvider client={queryClient}>
          <Auth>
            <ToastProvider>
              <BrowserRouter>
                <ContentObjectProvider>
                  <RouterConfig />
                  {/* <HomePage/> */}

                  {/*
                  <MainDashBoard />
                <ErrorBoundary>
                </ErrorBoundary>
*/}
                </ContentObjectProvider>
              </BrowserRouter>
            </ToastProvider>
          </Auth>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      )}
    </>
  );
}

export default App;

//           <Route path="/create-association-type" component={CreateContentAssociationType} />
//           <Route path="/create-category-type" component={CreateContentCategoryType} />
//           <Route path="/create-descriptor-type" component={CreateContentDescriptorType} />
//           <Route path="/create-facade-type" component={CreateContentFacadeType} />
//           <Route path="/create-index-type" component={CreateContentIndexType} />
//           <Route path="/create-templat-type" component={CreateContentTemplateType} />
