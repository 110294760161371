import React, { Component } from "react";
import { Link } from "react-router-dom";
import { generateKeyPair } from "crypto";

export default class HomePage extends Component {
  constructor(props) {
    console.log(props);
    super(props);
  }
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="container-fluid">
          <h1>Breeze</h1>
          <hr />
          <h4>Content Management</h4>
          <p>
            <a href="content-search" className="ContentTitle">
              Content Search
            </a>
          </p>
          <p>
            <a href="content-viewer" className="ContentTitle">
              Content Viewer
            </a>
          </p>
          <p>
            <a href="content-editor" className="ContentTitle">
              Content Editor
            </a>
          </p>

          <h4>Content Properties</h4>
          <p>
            <a href="/create-content" className="ContentTitle">
              Create Content
            </a>
          </p>
          <p>
            <a href="/create-content-type" className="ContentTitle">
              Create Content Type
            </a>
          </p>
          <p>
            <a href="/create-content-annotation" className="ContentTitle">
              Create Content Annotation
            </a>
          </p>
          <p>
            <a href="/create-content-application" className="ContentTitle">
              Create Content Application
            </a>
          </p>
          <p>
            <a href="/create-content-association" className="ContentTitle">
              Create Content Association
            </a>
          </p>
          <p>
            <a href="/create-content-association-type" className="ContentTitle">
              Create Content Association Type
            </a>
          </p>
          <p>
            <a href="/create-content-category" className="ContentTitle">
              Ceate Content Category
            </a>
          </p>
          <p>
            <a href="/create-content-category-type" className="ContentTitle">
              Create Content Category Type
            </a>
          </p>
          <p>
            <a href="/create-content-descriptor" className="ContentTitle">
              Ceate Content Descriptor
            </a>
          </p>
          <p>
            <a href="/create-content-descriptor-type" className="ContentTitle">
              Create Content Descriptor Type
            </a>
          </p>
          <p>
            <a href="/create-content-facade" className="ContentTitle">
              Create Content Facade
            </a>
          </p>
          <p>
            <a href="/create-content-facade-type" className="ContentTitle">
              Create Content Facade Type
            </a>
          </p>
          <p>
            <a href="/create-content-index" className="ContentTitle">
              Ceate Content Index
            </a>
          </p>
          <p>
            <a href="/create-content-index-type" className="ContentTitle">
              Create Content Index Type
            </a>
          </p>
          <p>
            <a href="/create-content-template" className="ContentTitle">
              Ceate Content Template
            </a>
          </p>
          <p>
            <a href="/create-content-template-type" className="ContentTitle">
              Create Content Template Type
            </a>
          </p>

          <hr />
          <h4>Manage Content Properties</h4>

          <p>
            <a href="manage-content" className="ContentTitle">
              Manage Content
            </a>
          </p>
          <p>
            <a href="manage-content-types" className="ContentTitle">
              Manage Content Types
            </a>
          </p>
          <p>
            <a href="manage-content-associations" className="ContentTitle">
              Manage Content Associations
            </a>
          </p>
          <p>
            <a href="manage-content-association-types" className="ContentTitle">
              Manage Content Associations Types
            </a>
          </p>
          <p>
            <a href="manage-content-categories" className="ContentTitle">
              Manage Content Categories
            </a>
          </p>
          <p>
            <a href="manage-content-category-types" className="ContentTitle">
              Manage Content Category Types
            </a>
          </p>
          <p>
            <a href="manage-content-descriptors" className="ContentTitle">
              Manage Content Descriptors
            </a>
          </p>
          <p>
            <a href="manage-content-descriptor-types" className="ContentTitle">
              Manage Content Descriptor Types
            </a>
          </p>
          <p>
            <a href="manage-content-facades" className="ContentTitle">
              Manage Content Facades
            </a>
          </p>
          <p>
            <a href="manage-content-facade-types" className="ContentTitle">
              Manage Content Facade Types
            </a>
          </p>
          <p>
            <a href="manage-content-indices" className="ContentTitle">
              Manage Content Indices
            </a>
          </p>
          <p>
            <a href="manage-content-index-types" className="ContentTitle">
              Manage Content Index Types
            </a>
          </p>
          <p>
            <a href="manage-content-templates" className="ContentTitle">
              Manage Content Templates
            </a>
          </p>
          <p>
            <a href="manage-content-template-types" className="ContentTitle">
              Manage Content Template Types
            </a>
          </p>
          <p>
            <a href="manage-content-transformations" className="ContentTitle">
              Manage Content Transformations
            </a>
          </p>
          <hr />
          <h4>Lists of Content Properties</h4>
        </div>
        <p>
          <a href="list-content-template-types" className="ContentTitle">
            List Content Template Types
          </a>
        </p>
        <p>
          <a href="list-content" className="ContentTitle">
            List Content Catalog
          </a>
        </p>
        <p>
          <a href="list-image-catalog" className="ContentTitle">
            List Image Catalog
          </a>
        </p>
        <p>
          <a href="list-content-association-types" className="ContentTitle">
            List Content Association Types
          </a>
        </p>
        <p>
          <a href="list-content-associations" className="ContentTitle">
            List Content Associations
          </a>
        </p>
        <p>
          <a href="list-content-categories" className="ContentTitle">
            List Content Categories
          </a>
        </p>
        <p>
          <a href="list-content-category-types" className="ContentTitle">
            List Content Category Types
          </a>
        </p>
        <p>
          <a href="list-content-descriptor-type" className="ContentTitle">
            List Content Descriptor Types
          </a>
        </p>
        <p>
          <a href="list-content-descriptors" className="ContentTitle">
            List Content Descriptors
          </a>
        </p>
        <p>
          <a href="list-content-facade-types" className="ContentTitle">
            List Content Facade Types
          </a>
        </p>
        <p>
          <a href="list-content-facades" className="ContentTitle">
            List Content Facades
          </a>
        </p>
        <p>
          <a href="list-content-index-types" className="ContentTitle">
            List Content Index Types
          </a>
        </p>
        <p>
          <a href="list-content-indices" className="ContentTitle">
            List Content Indices
          </a>
        </p>
        <p>
          <a href="list-content-template-types" className="ContentTitle">
            List Content Template Types
          </a>
        </p>
        <p>
          <a href="list-content-templates" className="ContentTitle">
            List Content Templates
          </a>
        </p>
        <p>
          <a href="list-content-types" className="ContentTitle">
            List Content Types
          </a>
        </p>
      </form>
    );
  }
}
