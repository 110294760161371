/** @format */
import React, { lazy, Suspense, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import HomePage2 from '../gazelle-edx/HomePage2'
// import * as ROUTES from "./constants";
import { AuthContext } from "../Auth/Auth";
import { Loading } from "../Loading";

// const Home = lazy(() => import("../DashBoards/DashBoardComponents/Home"));
const HomePage = lazy(() =>  import("../HomePage"));
const CreateContent = lazy(() =>  import("../create/CreateContent"));
const CreateContentType = lazy(() =>  import("../create/CreateContentType"));
const CreateContentApplication = lazy(() =>  import("../create/CreateContentApplication"));
//const { importNamespaceSpecifier } = lazy(() =>  import('@babel/types';
const CreateContentAnnotation = lazy(() =>  import("../create/CreateContentAnnotation"));
const CreateContentAssociations = lazy(() =>  import("../create/CreateContentAssociations"));
const createcontentassociationtype = lazy(() =>  import("../create/CreateContentAssociationType"));
const CreateContentCategories = lazy(() =>  import("../create/CreateContentCategories"));
const CreateContentCategoryType = lazy(() =>  import("../create/CreateContentCategoryType"));
const CreateContentDescriptor = lazy(() =>  import("../create/CreateContentDescriptors"));
const CreateContentDescriptorType = lazy(() =>  import("../create/CreateContentDescriptorType"));
const CreateContentFacades = lazy(() =>  import("../create/CreateContentFacades"));
const CreateContentFacadesType = lazy(() =>  import("../create/CreateContentFacadeType"));
const CreateContentIndexType = lazy(() =>  import("../create/CreateContentIndexType"));
const CreateContentIndex = lazy(() =>  import("../create/CreateContentIndices"));
const CreateContentTemplate = lazy(() =>  import("../create/CreateContentTemplates"));
const CreateContentTemplateType = lazy(() =>  import("../create/CreateContentTemplateType"));
const ManageContent = lazy(() =>  import("../manage/ManageContent"));
const ManageContentAssociations = lazy(() =>  import("../manage/ManageContentAssociations"));
const ManageContentAssociationsTypes = lazy(() =>  import("../manage/ManageContentAssociationTypes"));
const ManageContentCategories = lazy(() =>  import("../manage/ManageContentCategories"));
const ManageContentCategoryTypes = lazy(() =>  import("../manage/ManageContentCategoryTypes"));
const ManageContentDescriptors = lazy(() =>  import("../manage/ManageContentDescriptors"));
const ManageContentDescriptorTypes = lazy(() =>  import("../manage/ManageContentDescriptorTypes"));
const ManagecContentFacades = lazy(() =>  import("../manage/ManageContentFacades"));
const ManagecContentFacadeTypes = lazy(() =>  import("../manage/ManageContentFacadeTypes"));
const ManageContentTypes = lazy(() =>  import("../manage/ManageContentTypes"));
const ManageContentIndices = lazy(() =>  import("../manage/ManageContentIndices"));
const ManageContentIndexTypes = lazy(() =>  import("../manage/ManageContentIndexTypes"));
const ManageContentTemplates = lazy(() =>  import("../manage/ManageContentTemplates"));
const ManageContentTemplateTypes = lazy(() =>  import("../manage/ManageContentTemplateTypes"));
const ManageContentTransformations = lazy(() =>  import("../manage/ManageContentTransformations"));

const ListContent = lazy(() =>  import("../search/ListContent"));
const ListImageCatalog = lazy(() =>  import("../search/ListImageCatalog"));
const ListContentAssociationTypes = lazy(() =>  import("../search/ListContentAssociationTypes"));
const ListContentAssociations = lazy(() =>  import("../search/ListContentAssociations"));
const ListContentCategories = lazy(() =>  import("../search/ListContentCategories"));
const ListContentCategoryTypes = lazy(() =>  import("../search/ListContentCategoryTypes"));
const ListContentDescriptorTypes = lazy(() =>  import("../search/ListContentDescriptorTypes"));
const ListContentDescriptors = lazy(() =>  import("../search/ListContentDescriptors"));
const ListContentFacadeTypes = lazy(() =>  import("../search/ListContentFacadeTypes"));
const ListContentFacades = lazy(() =>  import("../search/ListContentFacades"));
const ListContentIndexTypes = lazy(() =>  import("../search/ListContentIndexTypes"));
const ListContentIndices = lazy(() =>  import("../search/ListContentIndices"));
const ListContentTemplateTypes = lazy(() =>  import("../search/ListContentTemplateTypes"));
const ListContentTemplates = lazy(() =>  import("../search/ListContentTemplates"));
const ListContentTypes = lazy(() =>  import("../search/ListContentTypes"));

const HomePage2 = lazy(() =>  import("../gazelle-edx/HomePage2"));
const CreateActivityCharge = lazy(() =>  import("../gazelle-edx/CreateActivityCharge"));
const CreateContainer = lazy(() =>  import("../gazelle-edx/CreateContainer"));
const CreateDocumentDelivery = lazy(() =>  import("../gazelle-edx/CreateDocumentDelivery"));
const CreateWorkOrder = lazy(() =>  import("../gazelle-edx/CreateWorkOrder"));
const CreateWorkOrderMaterial = lazy(() =>  import("../gazelle-edx/CreateWorkOrderMaterial"));
const ManageActivityCharges = lazy(() =>  import("../gazelle-edx/ManageActivityCharges"));
const ManageContainer = lazy(() =>  import("../gazelle-edx/ManageContainer"));
const ManageDocumentDelivery = lazy(() =>  import("../gazelle-edx/ManageDocumentDelivery"));
const ManageWorkOrder = lazy(() =>  import("../gazelle-edx/ManageWorkOrder"));
const ManageWorkOrderMaterials = lazy(() =>  import("../gazelle-edx/ManageWorkOrderMaterials"));
const ListActivityCharges = lazy(() =>  import("../gazelle-edx/ListActivityCharges"));
const ListDocumentDeliveries = lazy(() =>  import("../gazelle-edx/ListDocumentDeliveries"));
const ListWorkOrderMaterials = lazy(() =>  import("../gazelle-edx/ListWorkOrderMaterials"));
const listWorkOrder = lazy(() =>  import("../gazelle-edx/ListWorkOrders"));
const listContainers = lazy(() =>  import("../gazelle-edx/ListContainers"));

const SelectListComponents = lazy(() =>  import("../SelectListComponent"));
const ContentObjectProvider = lazy(() =>  import("../contexts/ContentObjectContext"));

const RouterConfig = ({ sessionObject, membershipType }) => {
  const userMetadata = useContext(AuthContext);

  return (
  	<>
  	{/* "this is a test inside the router config" */}
    
    <Suspense fallback={<Loading />}>
   
      <Switch>

          <Route path="/create-content" component={CreateContent} />
          <Route path="/create-content-type" component={CreateContentType} />
          <Route
            path="/Create-Content-annotation"
            component={CreateContentAnnotation}
          />
          <Route
            path="/Create-Content-application"
            component={CreateContentApplication}
          />
          <Route
            path="/create-content-association"
            component={CreateContentAssociations}
          />
          <Route
            path="/create-content-association-type"
            component={createcontentassociationtype}
          />
          <Route
            path="/create-content-category"
            component={CreateContentCategories}
          />
          <Route
            path="/create-content-category-type"
            component={CreateContentCategoryType}
          />
          <Route
            path="/create-content-descriptor"
            component={CreateContentDescriptor}
          />
          <Route
            path="/create-content-descriptor-type"
            component={CreateContentDescriptorType}
          />
          <Route
            path="/create-content-facade"
            component={CreateContentFacades}
          />
          <Route
            path="/create-content-facade-type"
            component={CreateContentFacadesType}
          />
          <Route
            path="/create-content-index-type"
            component={CreateContentIndexType}
          />
          <Route path="/create-content-index" component={CreateContentIndex} />
          <Route
            path="/create-content-template"
            component={CreateContentTemplate}
          />
          <Route
            path="/create-content-template-type"
            component={CreateContentTemplateType}
          />

          <Route path="/manage-content/:id" component={ManageContent} />
          <Route
            path="/manage-content-associations"
            component={ManageContentAssociations}
          />
          <Route
            path="/manage-content-association-types"
            component={ManageContentAssociationsTypes}
          />
          <Route
            path="/manage-content-categories"
            component={ManageContentCategories}
          />
          <Route
            path="/manage-content-category-types"
            component={ManageContentCategoryTypes}
          />
          <Route
            path="/manage-content-descriptors"
            component={ManageContentDescriptors}
          />
          <Route
            path="/manage-content-descriptor-types"
            component={ManageContentDescriptorTypes}
          />
          <Route
            path="/manage-content-facades"
            component={ManagecContentFacades}
          />
          <Route
            path="/manage-content-facade-types"
            component={ManagecContentFacadeTypes}
          />
          <Route path="/manage-content-types" component={ManageContentTypes} />
          <Route
            path="/manage-content-indices"
            component={ManageContentIndices}
          />
          <Route
            path="/manage-content-index-types"
            component={ManageContentIndexTypes}
          />
          <Route
            path="/manage-content-templates"
            component={ManageContentTemplates}
          />
          <Route
            path="/manage-content-template-types"
            component={ManageContentTemplateTypes}
          />
          <Route
            path="/manage-content-transformations"
            component={ManageContentTransformations}
          />

          <Route path="/list-content-catalog" component={ListContent} />
          <Route path="/list-image-catalog" component={ListImageCatalog} />
          <Route
            path="/list-content-association-types"
            component={ListContentAssociationTypes}
          />
          <Route
            path="/list-content-associations"
            component={ListContentAssociations}
          />
          <Route
            path="/list-content-categories"
            component={ListContentCategories}
          />
          <Route
            path="/list-content-category-types"
            component={ListContentCategoryTypes}
          />
          <Route
            path="/list-content-descriptor-type"
            component={ListContentDescriptorTypes}
          />
          <Route
            path="/list-content-descriptors"
            component={ListContentDescriptors}
          />
          <Route
            path="/list-content-facade-types"
            component={ListContentFacadeTypes}
          />
          <Route path="/list-content-facades" component={ListContentFacades} />
          <Route
            path="/list-content-index-types"
            component={ListContentIndexTypes}
          />
          <Route path="/list-content-indices" component={ListContentIndices} />
          <Route
            path="/list-content-template-types"
            component={ListContentTemplateTypes}
          />
          <Route
            path="/list-content-templates"
            component={ListContentTemplates}
          />
          <Route path="/list-content-types" component={ListContentTypes} />

          <Route path="/" exact component={HomePage2} />
          <Route
            path="/create-activity-charge"
            component={CreateActivityCharge}
          />
          <Route path="/create-container" component={CreateContainer} />
          <Route
            path="/create-document-delivery"
            component={CreateDocumentDelivery}
          />
          <Route path="/create-work-order" component={CreateWorkOrder} />
          <Route
            path="/create-work-order-material"
            component={CreateWorkOrderMaterial}
          />
          <Route
            path="/manage-activity-charges/:id"
            component={ManageActivityCharges}
          />
          <Route path="/manage-container/:id" component={ManageContainer} />
          <Route
            path="/manage-document-delivery/:id"
            component={ManageDocumentDelivery}
          />
          <Route path="/manage-work-order/:id" component={ManageWorkOrder} />
          <Route
            path="/manage-work-order-materials/:id"
            component={ManageWorkOrderMaterials}
          />
          <Route
            path="/list-activity-charges"
            component={ListActivityCharges}
          />
          <Route
            path="/list-document-deliveries"
            component={ListDocumentDeliveries}
          />
          <Route
            path="/list-work-order-materials"
            component={ListWorkOrderMaterials}
          />
          <Route path="/list-work-order" component={listWorkOrder} />
          <Route path="/list-containers" component={listContainers} />
          <Route path="/selectListComponent" component={SelectListComponents} />

		{/*
        <Route
          path={ROUTES.HOME}
          exact
          render={(props) => (
            <HomePage {...props} membershipType={membershipType} />
          )}
        />
        <Route path='/'>
          <Redirect to={ROUTES.HOME} />
        </Route>
        */}
        
        {/* <Route path="/" component={HomePage} /> */}
      </Switch>
    
     </Suspense>
   
    </>
  );
};

export default RouterConfig;
