import React, { useContext, useEffect, useState } from "react";
const ContentObjectContext = React.createContext();
export const useContentObjectContext = () => {
  return useContext(ContentObjectContext);
};

const ContentObjectProvider = ({ children }) => {
  const [currentContentObject, setCurrentContentObject] = useState(1);
  
  function setContentObject(id) {
    setCurrentContentObject(id);
  }

  const value = {
    currentContentObject,
    setContentObject,
  };
  return (
    <ContentObjectContext.Provider value={value}>
      {children}
    </ContentObjectContext.Provider>
  );
};

export default ContentObjectProvider;
